<template>
  <div>
    <Newheader />

    <div class="flex-col page">
      <div class="flex-col group">
        <div class="flex-col items-center group_1">
          <img data-aos="fade-up"
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279462598616779.png"
            class="image" />
          <div class="flex-row justify-between section_3">
            <span data-aos="fade-up" class="text_5">KYMCO i-Like</span>
            <div data-aos="fade-up" class="flex-row">
              <div class="flex-col items-center text-wrapper"><span class="text_6" @click="navPath('/drive')" style="cursor:pointer;">预约试驾</span></div>
              <div class="flex-col items-center text-wrapper_1"><a style="display:flex;align-items:center;" href="https://detail.tmall.com/item.htm?abbucket=6&id=659427969071&rn=ea0280898e20b6115ee7f2229564ad0a&spm=a1z10.3-b-s.w4011-23636442313.52.61f661edT82Rij&sku_properties=8369595:5557472"><span class="text_7" style="cursor:pointer;">购买商城</span></a></div>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_1">
         
            <video id="index_video" class="image"
          src="@/assets/video/like/1.mp4"
          style="object-fit: cover; width: 100%; height: 100%;" x5-playsinline="true" webkit-playsinline="true"
          playsinline="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true"
          autoplay="" muted="" loop=""></video>
          <div class="flex-col items-center section_5">
            <div class="group_3">
              <div data-aos="fade-up" class="text_8">国内首款复古智能电动摩托车</div>
              <div data-aos="fade-up" class="text_9">来自意大利设计大师经典之作</div>
            </div>
            <div @click="play(require('@/assets/video/like/2.mp4'))" data-aos="fade-up" class="flex-row justify-center section_6">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279462540644809.png"
                class="image_3" />
              <span class="text_10 cur">观看完整影片</span>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-col section_7">
          <div class="flex-col group_4">
            <div data-aos="fade-up" class="group_5">
              <span class="text_11">『参数化肌理』</span>
              <span class="text_12">前面板来自概念汽车设计理念</span>
              <span class="text_13">型面生动、更具科技感</span>
            </div>
            <div data-aos="fade-up" class="group_6">
              <span class="text_14">整车汲取了复古摩托车的经典动态线条，</span>
              <span class="text_15">大胆运用汽车设计语言，</span>
              <span class="text_16">使i-Like整体造型演绎出</span>
              <span class="text_18"><strong>摩登复古、未来科技</strong>的全新格调，</span>
              <span class="text_19">属于行业首创自成一派</span>
            </div>
            <div class="flex-row group_7">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279454166887781.png"
                class="image_4" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279454952118271.png"
                class="image_5" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279455952182528.png"
                class="image_6" />

              <img src="@/assets/images/ilike/blue.png" class="image_9" />
            </div>
          </div>
          <div class="flex-col group_8">
            <div data-aos="fade-up" class="group_9">
              <span class="text_20">电车的安静优雅，油车的加速体验，</span>
              <span class="text_21">乐在骑中。</span>
            </div>
            <div data-aos="fade-up" class="group_10">
              <span class="text_22">汲取自意大利艺术家乔治·莫兰迪灰系调色灵感，</span>
              <span class="text_23">以低饱和蓝白为基调，辅以咖棕座椅点缀，凝练出的低调高级感。</span>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" class="flex-row group_11">
          <div class="flex-col items-start group_12 hovescale">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279458349560799.png"
              class="image_32" />
            <div class="flex-col group_13">
              <span class="text_24">行业首款</span>
              <div class="group_14">
                <span class="text_25">3KW正弦波内转子侧挂电机</span>
                <span class="text_26">内部集成高效变速箱峰值扭矩高达17N.m</span>
              </div>
            </div>
          </div>
          <div class="image_12s hovescale">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279458534506300.png"
              class="image_32 image_12" />
            <span data-aos="fade-up" class="text_27">PTS油门控制技术</span>
            <div data-aos="fade-up" class="group_15">
              <span class="text_28">媲美燃油车的油门操控性的PTS油门控制技术</span>
              <span class="text_29">在享受线性加速的同时,还可获得更好的加速感</span>
            </div>
          </div>

        </div>

        <div data-aos="fade-up" class="flex-row group_16 ">
          <div class="flex-col group_17 hovescale">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279459692302184.png"
              class="image_32" />
            <div class="flex-col group_18">
              <span class="text_30">经得起，稳得住</span>
              <div class="group_19">
                <span class="text_31">轻量化铝合金锻造平叉设计，降服得住刁钻道</span>
                <span class="text_32">路的颠簸，一路体验更好的骑乘乐趣</span>
              </div>
            </div>
          </div>
          <div class="image_14 hovescale">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279459705880449.png"
              class="image_32 " />
              <span  class="text_33">名副骑实的舒适沙发</span>
        <div class="group_20" style="width: 100%;">
          <span class="text_34">运用KYMCO多年积累的舒适人体工学技术</span>
          <span class="text_35">专为电摩载人量身打造的宽大舒适座垫</span>
        </div>
          </div>
           

        </div>
        
      </div>
      <div class="flex-col group_21">
        <div data-aos="fade-up" class="flex-col items-end section_8">
          <img
            src="../assets/images/fanfu.png"
            class="image_33" />
          <div class="flex-col group_44">
            <div class="group_22">
              <span class="text_36">反复打磨细节,</span>
              <span class="text_37">优雅得360°无死角。</span>
            </div>
            <div class="group_23" >
              <span class="text_38">整车汲取了复古摩托车的经典动态线条。</span>
              <span class="text_39">大胆运用汽车设计语言，使i-Like整体造型演绎出</span>
              <span class="text_40">摩登复古、未来科技的全新格调。</span>
            </div>
          </div>
        </div>

        <div data-aos="fade-up" class="swiper swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="flex-col items-center section_91">

                <div class="flex-col group_46">
                  <div class="flex-col items-center group_24">
                    <span class="text_41">安全感都是车给的</span>
                    <span class="text_42">外表的从容源于内在十足的安全感</span>
                  </div>
                  <div class="flex-col group_25">
                    <div class="flex-row group_26">
                      <span class="text_43" @click="sclick(0)">全天候把握</span>
                      <span class="text_44" @click="sclick(1)">全面优异</span>
                      <span class="text_45" @click="sclick(2)">全能掌控</span>
                    </div>
                    <div class="flex-row">
                      <div class="section_10">
                        <!--*-->
                      </div>
                      <div class="section_14">
                        <!--*-->
                      </div>
                      <div class="section_14">
                        <!--*-->
                      </div>
                    </div>
                  </div>
                  <div class="group_27">
                    <span class="text_46">“大材小用”</span>
                    <span class="text_47">环抱式车架结构</span>
                  </div>
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279439117705341.png"
                    class="image_15" />
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="flex-col items-center section_92 ">

                <div class="flex-col group_46">
                  <div class="flex-col items-center group_24">
                    <span class="text_41">安全感都是车给的</span>
                    <span class="text_42">外表的从容源于内在十足的安全感</span>
                  </div>
                  <div class="flex-col group_25">
                    <div class="flex-row group_26">
                      <span class="text_43" @click="sclick(0)">全天候把握</span>
                      <span class="text_44" @click="sclick(1)">全面优异</span>
                      <span class="text_45" @click="sclick(2)">全能掌控</span>
                    </div>
                    <div class="flex-row">

                      <div class="section_14">
                        <!--*-->
                      </div>
                      <div class="section_10">
                        <!--*-->
                      </div>
                      <div class="section_14">
                        <!--*-->
                      </div>
                    </div>
                  </div>
                  <div class="group_27">
                    <span class="text_46">安全</span>
                    <span class="text_47">我们总想要更多</span>
                  </div>
                  <div class="image_15 qmyy">
                      <div>12项车规级动力电池技术</div>
                      <div>-20~60℃全天候满足电动摩托车</div>
                      <div>高能量密度、高放电倍率骑行要求</div>
                      <div>Ionex掌握核心BMS技术</div>
                      <div>为高端电动车而生！</div>
                  </div>
        
                  <!-- <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279439117705341.png"
                    class="image_15" /> -->
                </div>
              </div>


            </div>
            <div class="swiper-slide">
              <div class="flex-col items-center section_93 ">

                <div class="flex-col group_46">
                  <div class="flex-col items-center group_24">
                    <span class="text_41">安全感都是车给的</span>
                    <span class="text_42"> 源自内在十足的安全感</span>
                  </div>
                  <div class="flex-col group_25">
                    <div class="flex-row group_26 cur">
                      <span class="text_43 cur" @click="sclick(0)">全天候把握</span>
                      <span class="text_44 cur" @click="sclick(1)">全面优异</span>
                      <span class="text_45 cur" @click="sclick(2)">全能掌控</span>
                    </div>
                    <div class="flex-row">

                      <div class="section_14 cur">
                        <!--*-->
                      </div>
                      <div class="section_14 cur">
                        <!--*-->
                      </div>
                      <div class="section_10 cur">
                        <!--*-->
                      </div>
                    </div>
                  </div>
                  <div class="group_27">
                    <span class="text_46">智能，</span>
                    <span class="text_47">当以人为本</span>
                  </div>
                  <img
                    src="https://kymco.life/images/qnzw-icon.png"
                    class="image_15 qnzwImg" />
                </div>
              </div>

            </div>
          </div>
        </div>

        <constart car="ilikes"></constart>
      </div>
    </div>
    <newfooter />
  </div>
</template>

<script>
import constart from "../components/constart/index.vue"
export default {
  name: 'Vue3Brand',
  components: { constart },
  data() {
    return {
      sindex: 0,
      mySwiper: ""
    };
  },

  mounted() {
    console.log(gsap)
    setTimeout(() => {
      this.$nextTick(() => {
        //loading
        gsap.timeline({
          scrollTrigger: {
            trigger: ".image_9",
            scrub: .3,
            start: "5% center",
            end: "center top"
          }
        })
        .fromTo(".image_9", {
          opacity: 0
        }, {
          opacity: 1,
          duration: .2
        }).fromTo(".image_9", {
          xPercent: -30,
          yPercent: 10
        }, {
          xPercent: -5,
          yPercent: 0,
          duration: 1
        }, 0)

        gsap.timeline({
          scrollTrigger: {
            trigger: ".image_6",
            scrub: .3,
            start: "top 70%",
            end: "bottom bottom"
          }
        }).fromTo(".image_6", {
          opacity: 0
        }, {
          opacity: 1,
          duration: .2
        }).fromTo(".image_6", {
          xPercent: 30,
          yPercent: -10
        }, {
          xPercent: 2,
          yPercent: 0,
          duration: 1
        }, 0)

        this.mySwiper = new Swiper('.swiper', {
          effect: 'fade',
          loop: true,
          autoplay: true,//可选选项，自动滑动
        })

      })
    },100)

  },

  methods: {
    sclick(index) {

      this.mySwiper.slideToLoop(Number(index), 1000, false)
    }
  },
};
</script>

<style scoped lang="scss">
.qnzwImg{
  width: 550px !important;
  margin-left: 0 !important;
}
.qmyy{
  margin-left:0 !important;
  font-family:'Albbr';
  font-size: 24px;
  line-height: 36px;
  width: auto !important;
}
.page {
  background-color: #ffffff;
  width: 100%;


}



.group_1 {
  position: relative;
  z-index: 10;
}

.image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.section_3 {
  padding: 40px 359px;
  background-color: #f6f6f6;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.text_5 {
  margin: 5px 0 6px;
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 29px;
}

.text-wrapper {
  padding: 11px 0 10px;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  border: solid 2px #000000;
}

.text_6 {
  color: #000;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.text-wrapper_1 {
  margin-left: 31px;
  padding: 13px 0 12px;
  background-color: #007aff;
  border-radius: 20px;
  width: 140px;
  height: 40px;
}

.text_7 {
  color: #ffffff;
  font-size: 16px;
  font-family: 'Albbr';
  line-height: 15px;
}

.section_5 {
  padding: 354px 0 108px;
  background-color: #00000080;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_3 {
  text-align: center;
}

.text_8 {
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 72px;
  letter-spacing: 2.4px;
}

.text_9 {
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 72px;
  letter-spacing: 2.4px;
}

.section_6 {
  margin-top: 405px;
  padding: 20px 44px 20px 50px;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
}

.image_3 {
  width: 30px;
  height: 30px;
}

.text_10 {
  margin-left: 18px;
  margin-bottom: 3px;
  color: #000000;
  font-size: 25px;
  font-family: 'Albbm';
  line-height: 25px;
  letter-spacing: 1.25px;
}

.section_7 {
  padding-bottom: 577px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279453751118693.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 3530px;
}

.group_4 {
  margin-right: 12%;
  margin-top: -41px;
  padding: 526.5px 130px 777px 231px;
  position: relative;
}

.group_5 {
  align-self: flex-end;
  text-align: center;
}

.group_5 span {
  display: block;
}

.text_11 {
  color: #000;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 61px;
}

.text_12 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 61px;
}

.text_13 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 61px;
}

.group_6 {
  margin-top: 911.5px;
  align-self: flex-start;
}

.group_6 span {
  display: block;
}

.text_14 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.text_15 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.text_16 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.text_17 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_18 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.text_19 {
  color: #000;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.group_7 {
  height: 2702px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.image_4 {
  width: 642px;
  height: 384px;
  position: absolute;
  right: 496px;
  bottom: 0;
}

.image_5 {
  width: 642px;
  height: 384px;
  position: absolute;
  right: 0;
  bottom: 229px;
}

.image_6 {
  width: 955px;
  height: 1316px;
  position: absolute;
  right: 0;
  bottom: 143px;
}

.image_7 {
  width: 611px;
  height: 235px;
  position: absolute;
  left: 0;
  top: 1124px;
}

.image_8 {
  width: 859px;
  height: 239px;
  position: absolute;
  left: 460px;
  top: 1045px;
}

.image_9 {
  width: 1140px;
  height: 1271px;
  position: absolute;
  left: 0;
  top: 0;
}

.group_8 {
  margin-top: 20px;
  align-self: center;
  width: 1045px;
}

.group_9 {
  align-self: flex-start;
}

.text_20 {
  color: #000;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_21 {
  color: #000;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 60px;
}

.group_10 {
  margin-top: 32px;
}

.text_22 {
  color: #676767;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.text_23 {
  color: #676767;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 60px;
}

.group_11 {
  margin-top: -459px;
  align-self: center;
  width: 1082px;
  position: relative;
}

.group_12 {
  flex: 1 1 482px;
}

.image_32 {
  width: 480px;
  height: 600px;
}

.group_13 {
  margin-top: 52px;
  width: 443px;
}

.text_24 {
  margin-left: 3px;
  align-self: flex-start;
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 34px;
}

.group_14 {
  margin-top: 14px;
  line-height: 36px;
}

.text_25 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_26 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.image_12 {

  display: block;
}

.image_12s {
  margin-left: 120px;
  margin-top: 127px;
  flex: 1 1 482px;
}

.text_27 {

  margin-top: 36px;
  align-self: flex-end;
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 35px;
  display: block;
}

.group_15 {

  margin-top: 14px;
  align-self: flex-end;
  line-height: 36px;
}

.text_28 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
  display: block;
}

.text_29 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.group_16 {
  align-self: center;
  width: 1080px;
}

.group_17 {
  flex: 1 1 481px;
  width: 481px;
}

.group_18 {
  margin-top: 51px;
}

.text_30 {
  align-self: flex-start;
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 35px;
}

.group_19 {
  margin-top: 14px;
  line-height: 36px;
}

.text_31 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_32 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
}

.image_14 {
  margin-left: 119px;
  margin-top: 127px;
  flex: 1 1 481px;
}

.text_33 {
  margin-right: 764px;
  margin-top: 51px;
  align-self: flex-end;
  color: #000;
  font-size: 36px;
  font-family: 'Albbm';
  line-height: 35px;
  width: 100%;
  display: block;
}

.group_20 {
  margin-right: 636px;
  margin-top: 14px;
  align-self: flex-end;
  line-height: 36px;
  
}

.text_34 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
  display: block;
  width: 100%;
}

.text_35 {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 36px;
 
}

.group_21 {
  margin-top: 133px;
}

.section_8 {
  padding: 439px 0 437px;
  height: 1180px;
  position: relative;
}

.image_33 {
  width: 1920px;
  height: 1179.99936px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_44 {
  margin-right: 142px;
  width: 652px;
  position: relative;
}

.group_22 {
  align-self: flex-start;
}

.text_36 {
  color: #000;
  font-size: 40px;
  font-family: 'Albbm';
  line-height: 60px;
}

.text_37 {
  color: #000;
  font-size: 40px;
  font-family: 'Albbm';
  line-height: 60px;
}

.group_23 {
  margin-top: 40px;
  text-align: justify;
}

.text_38 {
  color: #676767;
  font-size: 30px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_39 {
  color: #676767;
  font-size: 30px;
  font-family: 'Albbr';
  line-height: 48px;
}

.text_40 {
  color: #676767;
  font-size: 30px;
  font-family: 'Albbr';
  line-height: 48px;
}

.section_91 {
  padding: 137px 0 163px;
  height: 1080px;
  background-image: url(../assets/images/ilike/bg/1.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.section_92 {
  padding: 137px 0 163px;
  height: 1080px;
  background-image: url(../assets/images/ilike/bg/2.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.section_93 {
  padding: 137px 0 163px;
  height: 1080px;
  background-image: url(../assets/images/ilike/bg/3.png);
  background-size: cover;
  background-position: center;
  position: relative;
}

.image_35 {
  width: 1920px;
  height: 1080px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.group_46 {
  width: 1224px;
  position: relative;
}

.group_24 {
  align-self: center;
}

.text_41 {
  color: #000;
  font-size: 48px;
  font-family: 'Albbm';
  line-height: 45px;
}

.text_42 {
  margin-top: 22px;
  color: #676767;
  font-size: 36px;
  font-family: 'Albbr';
  line-height: 35px;
}

.group_25 {
  margin-top: 47px;
  padding: 0 12px;
}

.group_26 {
  padding-bottom: 21px;
  align-self: center;
}

.text_43 {
  color: #000;
  font-size: 30px;
  font-family: 'Albbm';
  line-height: 29px;
}

.text_44 {
  margin-left: 263px;
  color: #696969;
  font-size: 30px;
  font-family: 'Albbm';
  line-height: 29px;
}

.text_45 {
  margin-left: 279px;
  color: #696969;
  font-size: 30px;
  font-family: 'Albbm';
  line-height: 28px;
}

.section_10 {
  background-color: #009de2;
  width: 400px;
  height: 4px;
}

.section_14 {
  background-color: #b5b5b5;
  width: 400px;
  height: 4px;
}

.group_27 {
  margin-top: 123.5px;
  align-self: flex-start;
}

.text_46 {
  color: #000;
  font-size: 40px;
  font-family: 'Albbm';
  line-height: 48px;
  display: block;
}

.text_47 {
  color: #000;
  font-size: 40px;
  font-family: 'Albbm';
  line-height: 48px;
}

.image_15 {
  margin-left: 12px;
  margin-top: 77.5px;
  align-self: flex-start;
  width: 260px;
  height: 280px;
}

.section_12 {
  padding: 126px 0 83px;
  background-color: #f6f6f6;
}

.text_48 {
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.text_49 {
  margin-left: 3px;
  margin-bottom: 3px;
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 40px;
}

.group_29 {
  margin-top: 56px;
  padding-top: 398px;
  width: 633px;
  position: relative;
}

.text-wrapper_2 {
  padding: 129px 0 9px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279439114596092.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 633px;
}

.text_50 {
  color: #80cdda;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
}

.image_16 {
  width: 526px;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.group_30 {
  margin-top: 33px;
  width: 332px;
}

.group_31 {
  padding-bottom: 31px;
}

.image_31 {
  width: 51px;
  height: 51px;
}

.image_36 {
  margin-left: 20px;
}

.text-wrapper_3 {
  padding: 26px 0;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0a0306;
}

.text_51 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.image-wrapper {
  padding: 179px 0 178px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16618279439091640877.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.image_20 {
  width: 1720px;
  height: 723px;
}

.hovescale img,
.hovescale {
  overflow: hidden;
  transition: all .5s;
}

.hovescale:hover img {
  transform: scale(1.07);
}
</style>